import React, { useState } from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/pageTitle'
import * as queryString from 'query-string'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql, useStaticQuery, Link } from 'gatsby'
import productList from '../components/productList'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ListTile from '../components/listTile'
import GoToTop from '../components/goToTop'

export const Query = graphql`
  {
    localSearchPages {
      index
      store
    }
  }
`

export default function Search({ location }) {
    const { s } = queryString.parse(location.search)
    const data = useStaticQuery(Query);

    const index = data.localSearchPages.index
    const store = data.localSearchPages.store

    //const [query, setQuery] = useState(s)
    const results = useFlexSearch(s, index, store)
    return (
        <Layout>
            <PageTitle
                title="Search Results"
                subtitle={results.length + " Results for " + s}
            />
            {results.length < 1 && <div className="text-center mx-auto text-lg my-6"> No products matching your search found.</div>}
            <div className="px-4 md:px-12 my-6 md:my-8 2xl:my-12">
                <GoToTop />
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-3">
                    {results.map(result => <ListTile product={result}/>)}
                </div>
            </div>
        </Layout>
    )
}
